<template>
	<div>
		<nav class="white">
			<div class="wrapper">
				<div class="logo">
					<img class="logo desktop" src="~/assets/images/logo.png" alt="Amadi Hotel" />
					<img class="logo mobile" src="~/assets/images/logo_white_footer.png" alt="Amadi Hotel" />
				</div>
			</div>
		</nav>
		<slot />
		<footer>
			<section class="row">
				<div class="columns column5 footerLinks">
					<a href="https://park.amadihotels.com/" target="_blank">Amadi Park Hotel</a>
					<a href="https://panorama.amadihotels.com/" target="_blank">Amadi Panorama Hotel</a>
				</div>
				<div class="columns column3">
					<div class="langNavWrap">
						<strong>Language:</strong>
						<ul class="langNav">
							<li class="currentLang">
								<img :src="images[`${currentLocale.code}.png`]" alt="" />
								<ul class="subLang" style="top: -29px">
									<li>
										<nuxt-link :to="switchLocalePath(otherLocale.code)">
											<img :src="images[`${otherLocale.code}.png`]" alt="" />
										</nuxt-link>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
				<div class="columns column2">
					<p>
						<strong>Amadi Hotels Amsterdam</strong><br />
						IJburglaan 539 <br />
						1087 BE Amsterdam <br />
						<a href="tel:+31202626900">+31 (0)20 2626 900</a> <br />
						<a href="mailto:info@amadihotels.com">info@amadihotels.com</a>
					</p>
				</div>
				<div class="columns column2">
					<a href="#" class="groupLink">
						<img src="~/assets/images/logo-white.png" alt="Group" />
					</a>
					<br />
				</div>
				<div class="columns column12">
					<span>{{ $t('copyright') }}</span>
				</div>
			</section>
		</footer>
	</div>
</template>

<script>
import { defineComponent } from 'vue';

const glob = import.meta.glob('@/assets/images/languages/*.png', { eager: true });

function filename(key) {
	return key.split('/').pop();
}

const images = Object.fromEntries(Object.entries(glob).map(([key, value]) => [filename(key), value.default]));

console.log(images);

export default defineComponent({
	computed: {
		images() {
			return images;
		},
		otherLocale() {
			return this.$i18n.locales.find((i) => i.code !== this.$i18n.locale);
		},
		currentLocale() {
			return this.$i18n.locales.find((i) => i.code === this.$i18n.locale);
		},
	},
});
</script>
